import { SkeletonBody } from "@sholdi/primitives/atoms/Skeleton";

const PopularCategoriesSkeleton = () => (
  <div className="w-full">
    <SkeletonBody className="w-80 h-[45px] mb-10 mx-auto block" />
    <SkeletonBody className="w-96 h-6 mb-2 mx-auto block" />
    <SkeletonBody className="w-60 h-6 mb-10 mx-auto block" />

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {Array(6)
        .fill(0)
        .map((_, i) => (
          <SkeletonBody key={i} className="h-[480px]" />
        ))}
    </div>
  </div>
);

export default PopularCategoriesSkeleton;
