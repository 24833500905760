import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Box from "../Box";

export const Body = ({ className = "", ...props }) => (
  <Box
    className={twMerge(
      `m-1 bg-gray-lighter overflow-hidden relative rounded-md animate-pulse`,
      className,
    )}
    {...props}
  />
);

Body.propTypes = {
  className: PropTypes.string,
};

Body.displayName = "SkeletonBody";

export default Body;
