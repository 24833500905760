import PropTypes from "prop-types";
import dynamic from "next/dynamic";

import BannerLink from "../BannerLink";

import GallerySkeleton from "./GallerySkeleton";
import ProductListSkeleton from "./ProductListSkeleton";
import CardListSkeleton from "./CardListSkeleton";
import GridSkeleton from "./GridSkeleton";
import PopularCategoriesSkeleton from "./PopularCategoriesSkeleton";
import React from "react";

const GridLayout = dynamic(() => import("../../molecules/GridLayout"), {
  loading: GridSkeleton,
});

const BannerLinksGallery = dynamic(
  () => import("../../molecules/BannerLinksGallery"),
  {
    loading: GallerySkeleton,
  },
);

const ProductList = dynamic(() => import("../ProductList"), {
  loading: ProductListSkeleton,
});

const ListHeader = dynamic(() => import("../../molecules/ListHeader"));
const CardList = dynamic(() => import("../CardList"), {
  loading: CardListSkeleton,
});
const PopularCategories = dynamic(() => import("./PopularCategories"), {
  loading: PopularCategoriesSkeleton,
});

const PageBanners = ({ sections = [], ...props }) => (
  <div className="flex flex-col w-full gap-12 lg:gap-16 pt-12">
    {sections.map((section, i) => {
      if (section.type === "product" && props[`products_${section.sort}`]) {
        return (
          <ProductList
            key={i}
            products={props[`products_${section.sort}`]}
            header={
              <ListHeader
                title={section.sectionTitle}
                href={section.cta.href}
                subtitle={section.sectionSubtitle}
                titleProps={{ className: "mb-3" }}
              />
            }
          />
        );
      }
      if (section.type === "category" && props[`categories_${section.sort}`]) {
        return (
          <PopularCategories
            key={i}
            mainCategories={props[`categories_${section.sort}`]}
          />
        );
      }
      if (section.type === "shopCard" && props[`shops_${section.sort}`]) {
        return (
          <CardList
            key={i}
            title={section.sectionTitle}
            data={props[`shops_${section.sort}`]}
          />
        );
      }
      if (section.type === "gallery") {
        return (
          <React.Fragment key={i}>
            {section.sectionTitle && (
              <ListHeader
                title={section.sectionTitle}
                href={section.cta.href}
                subtitle={section.sectionSubtitle}
                className="mb-0"
              />
            )}
            <BannerLinksGallery key={i} banners={section.banners} />
          </React.Fragment>
        );
      }
      if (section.type === "gridBanner") {
        return (
          <React.Fragment key={i}>
            {section.sectionTitle && (
              <ListHeader
                title={section.sectionTitle}
                href={section.cta.href}
                subtitle={section.sectionSubtitle}
              />
            )}
            <GridLayout
              layouts={section.layouts}
              classNames={props[`classNames_${section.sort}`]}
              // rowHeight="150px"
              className="min-h-[600px]"
            >
              {section.banners.map((banner) => (
                <BannerLink
                  key={banner.sort}
                  banner={banner}
                  variant="gridCard"
                />
              ))}
            </GridLayout>
          </React.Fragment>
        );
      }
      return null;
    })}
  </div>
);

PageBanners.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      sort: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      banners: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
};

export default PageBanners;
