import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const ChevronLeft = ({ className, strokeWidth, ...props }) => (
  <SVG
    aria-label="ChevronLeft"
    className={twMerge("w-3 h-3 stroke-2", className)}
    {...props}
  >
    <path
      d="M15 18L9 12L15 6"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

ChevronLeft.propTypes = {
  className: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default ChevronLeft;
