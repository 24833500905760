import SkeletonProductList from "../../molecules/SkeletonProductList";
import { SkeletonBody } from "@sholdi/primitives/atoms/Skeleton";

const ProductListSkeleton = () => (
  <div className="w-full">
    <SkeletonBody className="block w-48 h-6" />
    <SkeletonBody className="w-60 h-6 mb-10" />
    <SkeletonProductList items={6} />
  </div>
);

export default ProductListSkeleton;
