import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

import Text from "@sholdi/primitives/atoms/Text";
import Image from "@sholdi/primitives/atoms/Image";
import Link from "@sholdi/primitives/atoms/Link";

import getVariantChildStyles from "@sholdi/shared/helpers/getVariantChildStyles";

const variants = {
  round: {
    tag: "hidden",
    date: "hidden",
    imageWrapper: "",
    wrapper: "flex-col items-center hover:no-underline",
    image: "w-[150px] h-[150px] rounded-full",
    title:
      "text-center font-heading mt-4 text-base lg:text-base font-normal text-primary-text",
    subtitle: "hidden",
  },
  square: {
    tag: "hidden",
    date: "hidden",
    wrapper: "flex-col items-center bg-white rounded-sm border border-gray-400",
    title: "hidden",
    subtitle: "hidden",
    image: "w-[70%] h-[full]",
  },
  card: {
    date: "hidden",
    tag: "hidden",
    wrapper: "flex-col items-center hover:no-underline",
    title: "font-heading mt-4 text-base lg:text-base font-normal",
    subtitle:
      "font-heading mt-1 text-base lg:text-base font-normal text-center",
    image: "w-full h-[220px] rounded-md",
  },
  cardLight: {
    wrapper: "flex-col bg-white rounded-md hover:no-underline",
    subtitle: "font-heading mt-1 text-base lg:text-base p-4",
    tag: "transform -translate-y-1/2 bg-primary-main w-fit px-2 py-1 rounded-sm ml-4 self-start font-semibold text-white text-sm md:text-sm lg:text-sm",
    image: "h-[200px] w-full rounded-t-md",
    date: "text-xs lg:text-xs font-heading text-gray-500 px-4 py-2",
    title: "font-heading mt-1 text-base lg:text-base p-4 font-semibold",
  },
  gridCard: {
    wrapper: "relative rounded overflow-hidden hover:shadow-md p-4",
    content: "mx-4 transition-all duration-300 ease-in-out",
    image: "transition-all duration-300 ease-in-out delay-200",
    title:
      "text-lg lg:text-2xl decoration-white font-semibold lg:font-bold leading-6 lg:leading-6",
    cta: "text-white font-medium block mt-2 leading-5",
    subtitle: "leading-5",
  },
};

const POSITIONS = {
  bottomLeft: () => "justify-end",
  bottomRight: () => "justify-end items-end",
  topLeft: () => "",
  topRight: () => "items-end",
};

const bannerLinkWrapper = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "wrapper"),
  },
});

export const BannerLinkWrapper = ({ variant, className, ...props }) => (
  <div
    className={twMerge(bannerLinkWrapper({ variant }), className)}
    {...props}
  />
);

BannerLinkWrapper.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const bannerImage = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "image"),
  },
});

export const BannerLinkImage = ({ variant, className, ...props }) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <Image className={twMerge(bannerImage({ variant }), className)} {...props} />
);

BannerLinkImage.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const bannerLinkTag = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "tag"),
  },
});

export const BannerLinkTag = ({ variant, className, ...props }) => (
  <Text className={twMerge(bannerLinkTag({ variant }), className)} {...props} />
);

BannerLinkTag.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const bannerLinkContent = cva("flex flex-col w-full", {
  variants: {
    variant: getVariantChildStyles(variants, "content"),
  },
});

export const BannerLinkContent = ({
  variant,
  className,
  isGridCard,
  contentPosition,
  ...props
}) => (
  <div
    className={twMerge(
      isGridCard ? "h-full" : "unset",
      className,
      POSITIONS[contentPosition || "topLeft"](props),
      bannerLinkContent({ variant }),
    )}
    {...props}
  />
);

BannerLinkContent.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  isGridCard: PropTypes.bool,
  contentPosition: PropTypes.string,
};

const bannerLinkCTA = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "cta"),
  },
});

export const BannerLinkCTA = ({ variant, className, ...props }) => (
  <Link className={twMerge(className, bannerLinkCTA({ variant }))} {...props} />
);

BannerLinkCTA.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const bannerLinkTitle = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "title"),
  },
});

export const BannerLinkTitle = ({ variant, className, ...props }) => (
  <Text
    className={twMerge(className, bannerLinkTitle({ variant }))}
    {...props}
  />
);

BannerLinkTitle.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const bannerLinkDate = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "date"),
  },
});

export const BannerLinkDate = ({ variant, className, ...props }) => (
  <Text
    className={twMerge(className, bannerLinkDate({ variant }))}
    {...props}
  />
);

BannerLinkDate.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const bannerLinkSubtitle = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "subtitle"),
  },
});

export const BannerLinkSubtitle = ({ variant, className, ...props }) => (
  <Text
    className={twMerge(bannerLinkSubtitle({ variant }), className)}
    {...props}
  />
);

BannerLinkSubtitle.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};
