import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const ChevronRight = ({ className, strokeWidth, ...props }) => (
  <SVG
    aria-label="ChevronRight"
    className={twMerge("w-3 h-3 stroke-2", className)}
    {...props}
  >
    <path
      d="M9 18L15 12L9 6"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

ChevronRight.propTypes = {
  className: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default ChevronRight;
